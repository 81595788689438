<template>
  <div>
    <div class="bg-img-hero" :style="{ backgroundImage: `url(${require(`@/assets/svg/components/general-bg-3.svg`)})`}">
      <div class="container">
        <!-- logo -->
        <div class="space-1 text-center">
          <a href="https://www.openapihub.com/" target="_blank" class="navbar-brand" aria-label="OpenAPIHub by beNovelty">
            <img src="@/assets/svg/logos/logo.svg" alt="OpenAPIHub">
          </a>
        </div>
        <!-- content -->
        <div class="space-1">
          <div class="w-md-80 mx-auto text-center mb-7">
            <h2>{{ $t('SignUpConfirmationPage.Title') }}</h2>
            <img class="img-fluid w-50" src="@/assets/svg/illustrations/send.svg">
            <p><span v-html="$t('SignUpConfirmationPage.Description')" /></p>
          </div>
          <hr>
          <div class="mt-7">
            <div class="w-md-60 mx-auto text-center mb-6">
              <p><span v-html="$t('SignUpConfirmationPage.OahDescription')" /></p>
            </div>
            <div class="row w-lg-90 mx-auto">
              <div v-for="(card, index) in registerCards" :key="index" class="col-12 col-md-4 mb-4 mb-lg-0">
                <a class="card card-bg-light h-100 shadow-soft overflow-hidden transition-3d-hover" :href="card.url" target="_blank">
                  <div class="row align-items-center no-gutters">
                    <div class="col-12 col-sm-8 col-md-12">
                      <div class="py-4 px-4">
                        <h3 class="mb-3">{{ $t(card.title) }}</h3>
                        <span class="font-weight-bold">{{ $t(card.button) }} <i class="fas fa-angle-right fa-sm ml-1" /></span>
                      </div>
                    </div>
                    <div class="col-12 col-sm-4 col-md-12 px-0">
                      <img class="img-fluid w-80 w-sm-100 w-lg-80 float-right" :src="require(`@/assets/svg/illustrations/${card.img}.svg`)" alt="SVG">
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- footer -->
        <footer class="space-2 space-top-3 text-center">
          <a href="https://www.openapihub.com/" target="_blank" class="d-inline-flex align-items-center mb-3" aria-label="OpenAPIHub by beNovelty">
            <img class="brand" src="@/assets/svg/logos/logo.svg" alt="Logo">
          </a>
          <p class="font-size-1 mb-1"><span v-html="$t('SignUpConfirmationPage.Footer.Description')" /></p>
          <p class="small text-muted">© {{ new Date().getFullYear() }} {{ $t('SignUpConfirmationPage.Footer.Copyright') }}</p>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import 'bootstrap'

export default {
  name: 'SignupConfirmation',
  data() {
    return {
      registerCards: [
        {
          title: 'SignUpConfirmationPage.Card1.Title',
          img: 'learn-api',
          button: 'SignUpConfirmationPage.Card1.Button',
          url: 'https://www.openapihub.com/academy/'
        },
        {
          title: 'SignUpConfirmationPage.Card2.Title',
          img: 'api-product',
          button: 'SignUpConfirmationPage.Card2.Button',
          url: 'https://www.openapihub.com/api-provider'
        },
        {
          title: 'SignUpConfirmationPage.Card3.Title',
          img: 'api-hub',
          button: 'SignUpConfirmationPage.Card3.Button',
          url: 'https://www.openapihub.com/enterprise-api-hub'
        }
      ]
    }
  }
}
</script>

  <style lang="scss" scoped>

  </style>

